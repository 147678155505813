<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">联系我们</h1>
				<div class="clearfix"></div>
			</div>
			<!-- 我的订单头部END -->

			<!-- 我的订单主要内容 -->
			<div class="box-bd">
				<div class="userInfo">
					<div>联系人：阮宇</div>
					<div>联系电话：13977857526</div>
					<div>经营地址：深圳市龙华区大浪街道同胜社区三合华侨新村11号7层762室</div>
				</div>
			</div>
			<!-- 我的订单主要内容END -->
		</div>
	</div>
</template>
<script>
	import {
		getToken
	} from '@/utils/auth'
	export default {
		name: 'Order',
		data() {
			return {
				IsChecked: false,
				dataValues: {
					RealName: '', //联系人
					Name: '', //名称
					Mobile: '', //手机号
					Address: '', //地址
					IdCard: '', //身份证号码
					BusinessImg: '', //营业执照
					IdImg1: '', //身份证正面
					IdImg2: '' //身份证反面
				},
				uploadUrl: this.$target + '/UploadUploadFile',
				BusinessImgList: [],
				IdImg1List: [],
				IdImg2List: [],
				headers: {}
			};
		},
		created() {
			if (localStorage.getItem("userToken")) {
				this.headers['Authorization'] = `Bearer ${getToken()}`
			}
			
		},
		methods: {
			async SubmitShopInfo() {
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				if (this.dataValues.RealName == '' || !this.dataValues.RealName) {
					this.notifyError('联系人不能为空');
					return false
				}
				if (this.dataValues.Name == '' || !this.dataValues.Name) {
					this.notifyError('店铺名称不能为空');
					return false
				}
				if (this.dataValues.Mobile == '' || !this.dataValues.Mobile) {
					this.notifyError('手机号不能为空');
					return false
				}
				if (this.dataValues.Address == '' || !this.dataValues.Address) {
					this.notifyError('地址不能为空');
					return false
				}
				if (this.dataValues.IdCard == '' || !this.dataValues.IdCard) {
					this.notifyError('身份证号码不能为空');
					return false
				}
				if (this.dataValues.BusinessImg == '' || !this.dataValues.BusinessImg) {
					this.notifyError('请上传营业执照');
					return false
				}
				if (this.dataValues.IdImg1 == '' || !this.dataValues.IdImg1) {
					this.notifyError('请上传身份证正面');
					return false
				}
				if (this.dataValues.IdImg2 == '' || !this.dataValues.IdImg2) {
					this.notifyError('请上传身份证反面');
					return false
				}

				this.IsChecked = true
				const res = await this.postdata('/Shop/SumbitShop', this.dataValues)
				if (res.code == 200) {
					this.notifySucceed("提交成功,等待后台审核");
					this.dataValues={
						RealName: '', //联系人
						Name: '', //名称
						Mobile: '', //手机号
						Address: '', //地址
						IdCard: '', //身份证号码
						BusinessImg: '', //营业执照
						IdImg1: '', //身份证正面
						IdImg2: '' //身份证反面
					}
				}
				this.IsChecked = false
			},
			handleRemove(file, fileList, type) {
				//type  0.营业执照 1.身份证正面 2.身份证反面
				switch (type) {
					case 0:
						this.dataValues.BusinessImg = ''
						this.BusinessImgList = []
						break;
					case 1:
						this.dataValues.IdImg1 = ''
						this.IdImg1List = []
						break;
					case 2:
						this.dataValues.IdImg2 = ''
						this.IdImg2List = []
						break;
					default:
						break;
				}
			},
			handleSuccess(res, file, fileList, type) {
				//type  0.营业执照 1.身份证正面 2.身份证反面
				switch (type) {
					case 0:
						this.dataValues.BusinessImg = res.data
						break;
					case 1:
						this.dataValues.IdImg1 = res.data
						break;
					case 2:
						this.dataValues.IdImg2 = res.data
						break;
					default:
						break;
				}
			}

		}

	}
</script>
<style scoped>
	/* 订单为空的时候显示的内容CSS */
	.userInfo .Personalbtn {
		margin-left: 18%;
		margin-top: 16px;
	}

	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.box {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}

	.userInfo {
		font-size: 24px;
		font-weight: 500;
		line-height: 36px;
	}



	.box-bd {
		margin-top: 16px;
	}

	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}

	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #757575;
		cursor: pointer
	}

	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #ff6700
	}

	.hide {
		display: none !important
	}

	.uc-order-item {
		position: relative
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #ff6700;
		border-color: #ff6700;
		color: #fff;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	/* 订单为空的时候显示的内容CSS END */
</style>
